footer {
  background: $black;
  .top {
    display: flex;
    justify-content: space-between;
    padding: r(80) 0 r(70);
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: r(34) 0 r(31);
    border-top: 1px solid $light-green;
  }
  .ps-link {
    display: flex;
    align-items: flex-end;
    .icon {
      width: r(110);
      height: r(23);
      margin-left: r(10);
      fill: currentColor;
    }
  }
  .logo {
    width: r(437);
    height: r(160);
    flex: 0 0 auto;
    fill: #fff;
  }
  .menus {
    display: flex;
    justify-content: space-between;
    flex: 0 0 60%;
    color: #fff;
    .link {
      display: block;
      &:not(:last-child) {
        margin-bottom: r(18);
      }
    }
    .col-name {
      margin-bottom: r(23);
    }
  }
  @media (max-width: 1279px) {
    .top {
      flex-direction: column;
      padding: r(23) 0 r(40);
    }
    .menus {
      flex-direction: column;
      gap: r(40);
      margin-top: r(23);
      .col-name {
        margin-bottom: r(25);
        font-weight: 700;
      }
      .link {
        &:not(:last-child) {
          margin-bottom: r(25);
        }
      }
    }
    .logo {
      width: r(249);
      height: r(91);
    }
    .bottom {
      flex-direction: column;
      align-items: flex-start;
      padding: r(39) 0 r(30);
      gap: r(25);
    }
    .ps-link {
      align-items: center;
      .icon {
        width: r(102);
        height: r(21);
        margin-left: r(5);
      }
    }
  }
}
