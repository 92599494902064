.overflow {
    &-hidden {
        overflow: hidden;
    }

    &-visible {
        overflow: visible;
    }
}

.bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.collapse {
    overflow: hidden;
    transition: height var(--collapse-time, #{$tr-time * 2});

    &.is-collapsed {
        height: 0;
    }
}

.link-cover {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: r(15) r(30) r(16);
    text-align: center;
    font-size: r(20);
    color: var(--text-color) !important;
    text-decoration: none !important;
    border: 1px solid var(--border-color);
    border-radius: r(10);
    background: var(--bg-color);
    font-family: "Inter", sans-serif;
    cursor: pointer;

    &-small {
        padding: r(12) r(20) r(14);
        font-size: r(16);
    }

    &-lightgreen {
        --text-color: #fff;
        --border-color: #{$light-green};
        --bg-color: #{$light-green};

        &:hover {
            --border-color: #{$dark-green};
            --bg-color: #{$dark-green};
        }
    }

    &-white-border {
        --text-color: #fff;
        --border-color: #fff;
        --bg-color: #{$light-green};

        &:hover {
            --border-color: #{$dark-green};
            --bg-color: #{$dark-green};
        }
    }

    &-darkgreen-border {
        --text-color: #{$dark-green};
        --border-color: #{$dark-green};
        --bg-color: transparent;

        &:hover {
            --text-color: #fff;
            --border-color: #{$dark-green};
            --bg-color: #{$dark-green};
        }
    }

    &-lightgreen-border {
        --text-color: #{$light-green};
        --border-color: #{$light-green};
        --bg-color: transparent;

        &:hover {
            --text-color: #fff;
            --bg-color: #{$light-green};
        }
    }

    &.has-icon {
        justify-content: space-between;
    }

    .arrow {
        width: r(17);
        height: r(13);
        flex: 0 0 auto;
        margin-left: r(20);
        fill: currentColor;
    }

    &:not(:disabled) {
        transition-duration: $tr-time;
        transition-property: color, border-color, background-color, box-shadow;
    }

    &:disabled {
        pointer-events: none;
        transition-duration: 0ms;
        --text-color: transparent;
    }

    @media (max-width: 1279px) {
        font-size: r(14);

        .arrow {
            width: r(12);
            height: r(9);
            margin-left: r(14);
        }
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 93.65vw;
    padding-left: r(14);
    padding-right: r(14);

    @media (max-width: 1279px) {
        max-width: 100%;
    }
}

.link {
    &:hover {
        text-decoration: underline;
    }
}

.h1 {
    @include h1;
}

.h2 {
    @include h2;
}

.h3 {
    @include h3;
}

.text1 {
    @include text1;
}

.text2 {
    @include text2;
}

.caption {
    @include caption;
}

.mob-text2 {
    @media (max-width: 1279px) {
        @include mob-text2;
    }
}

.mob-text1 {
    @media (max-width: 1279px) {
        @include mob-text1;
    }
}

.font-inter {
    font-family: "Inter", sans-serif;
}

.darkgreen-color {
    color: $dark-green;
}

.lightgreen-color {
    color: $light-green;
}

.orange-color {
    color: $orange;
}

.search-btn {
    width: r(120);
    flex: 0 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    height: r(58);

    .icon {
        width: r(28);
        height: r(28);
        fill: currentColor;
    }

    &.compact {
        width: r(60);
        padding: 0;

        .icon {
            width: r(24);
            height: r(24);
        }
    }

    @media (max-width: 1279px) {
        width: r(41);
        height: r(41);
        padding: 0;

        .icon {
            width: r(21);
            height: r(21);
        }
    }
}

.page-head+.section {
    margin-top: r(58);
}

.hidden {
    display: none !important;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1279px) {
    .page-head+.section {
        margin-top: r(34);
    }
}