.modal-vacancy {
  .form-col {
    --form-personal-color: #{$dark-green};
    .form {
      width: 65%;
      margin: 0 auto;
      padding: r(45) r(28) r(49);
      background: #fff;
      border-radius: r(30);
    }
    .title {
      margin-bottom: r(29);
      text-align: center;
    }
    .submit-btn {
      margin-top: r(21);
      width: 100%;
    }
    .personal {
      margin-top: r(20);
      text-align: center;
    }
    .row-input {
      width: 100%;
      margin-bottom: r(25);
    }
  }
  @media (max-width: 1279px) {
    .form-col {
      margin: r(50) r(-20) r(-20);
      .form {
        padding: r(25) r(20);
        width: 100%;
      }
      .title {
        margin-bottom: r(20);
        font-size: r(30);
      }
      .row-input {
        margin-bottom: r(15);
      }
      .submit-btn {
        margin-top: r(10);
      }
    }
  }
}
