.advantages-section {
  margin: r(160) 0 r(290);
  .title {
    width: 30%;
    margin: 0 0 r(100) r(25);
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: r(30);
  }
  .advantage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: r(450);
    padding: r(35);
    color: var(--text-color);
    background: var(--bg-color);
    border-radius: r(60);
    break-inside: avoid;
    .icon {
      width: r(130);
      height: r(124);
      align-self: flex-end;
      fill: var(--icon-color);
    }
    .description {
      margin-top: r(12);
    }
    &.lightgreen {
      --bg-color: #{$light-green};
      --text-color: #fff;
      --icon-color: #{$dark-green};
    }
    &.darkgreen {
      --bg-color: #{$dark-green};
      --text-color: #fff;
      --icon-color: #fff;
    }
    &.white {
      --bg-color: #fff;
      --text-color: #{$dark-green};
      --icon-color: #{$light-green};
      .description {
        color: $black;
      }
    }
    &.icon-top {
      .name,
      .name-row {
        order: 1;
      }
    }
    &:nth-child(3n + 2) {
      transform: translate(0, r(120));
    }
    &:nth-child(3n + 3) {
      transform: translate(0, r(-120));
    }
  }
  @media (max-width: 1279px) {
    margin: r(50) 0;
    .grid {
      grid-template-columns: 1fr;
      gap: r(20);
    }
    .title {
      width: 100%;
      margin: 0 0 r(22);
    }
    .advantage {
      height: r(212);
      padding: r(20) r(10) r(10) r(20);
      transform: none !important;
      border-radius: r(30);
      .icon {
        width: r(97);
        height: r(92);
      }
      .description {
        margin: r(5) 0 0;
      }
    }
  }
}
