.work-plan {
  padding: r(70) 0;
  background: #fff;
  .title {
    margin-bottom: r(50);
  }
  .step {
    .icon {
      width: r(130);
      height: r(124);
      margin-bottom: r(30);
      fill: transparent;
    }
    .text {
      margin-top: r(20);
    }
  }
  .steps {
    display: flex;
    gap: r(140);
  }
  @media (max-width: 1279px) {
    padding: r(40) 0;
    .steps {
      flex-direction: column;
      align-items: center;
      gap: r(40);
    }
    .name {
      font-size: r(20);
    }
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: r(115);
        height: r(109);
        margin-bottom: r(15);
      }
      .text {
        margin-top: r(10);
        text-align: center;
      }
    }
  }
}
