.doctor-card {
  position: relative;
  background: #fff;
  border-radius: r(30);
  .bottom {
    padding: r(35) r(30) r(40);
  }
  .position {
    opacity: 0.45;
    margin-bottom: r(15);
  }
  .name {
    margin-bottom: r(32);
  }
  .img {
    width: 100%;
    height: r(404);
    border-radius: r(30);
    object-fit: cover;
  }
  @media (max-width: 1279px) {
    .img {
      height: r(328);
    }
    .bottom {
      padding: r(20);
    }
  }
}
