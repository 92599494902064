.breadcrumbs {
  li {
    display: inline;
    &:first-child {
      opacity: 0.45;
    }
  }
  @media (max-width: 1279px) {
    li {
      font-size: r(12);
    }
  }
}
