.recomend-sidebar {
  border-radius: r(30);
  overflow: hidden;
  .top {
    padding: r(20) r(30);
    color: #fff;
    font-weight: 600;
    background: $black;
  }
  .analysis-card {
    border-radius: 0;
    box-shadow: none;
    .add-to-bookmark {
      top: r(36);
    }
    .name {
      width: 80%;
    }
    &:not(:first-child) {
      &::before {
        content: "";
        height: 1px;
        position: absolute;
        top: 0;
        right: r(30);
        left: r(30);
        background: $pale-green;
      }
    }
  }
}
