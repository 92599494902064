.reviews-section {
    margin: r(170) 0 r(150);

    .grid {
        width: 84.7%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 39% 53%;
        justify-content: space-between;
        align-items: start;
    }

    .subtitle {
        margin-bottom: r(28);
    }

    .title {
        margin-bottom: r(54);
    }

    .reviews-link {
        &:not(:last-child) {
            margin-bottom: r(30);
        }
    }

    .btns {
        display: flex;
        gap: r(30);
        margin-top: r(83);
    }

    .slider-col {
        grid-row: span 2;
        position: relative;
    }

    .swiper {
        width: 70%;
        margin: 0 auto;

        &-slide {
            padding: r(42) r(46) r(46);
            background: #fff;
            border-radius: r(30);

            .rating-row {
                display: flex;
                gap: r(20);
                margin-bottom: r(25);
                --star-size: #{r(23)};
                --star-active-color: #{$dark-green};
            }

            .counter {
                margin: 0 0 r(56) r(10);
                color: rgba($dark-green, 0.45);

                .current {
                    color: $dark-green;
                }
            }

            .name {
                margin-bottom: r(15);
            }
        }
    }

    .slider-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

        &.prev {
            left: 0;
        }

        &.next {
            right: 0;
        }
    }

    @media (max-width: 1279px) {
        margin: r(60) 0;

        .grid {
            width: 100%;
            display: block;
        }

        .title {
            margin-bottom: r(42);
        }

        .subtitle {
            margin-bottom: r(20);
        }

        .reviews-link {
            &:not(:last-child) {
                margin-bottom: r(20);
            }
        }

        .swiper {
            width: 81%;

            &-slide {
                padding: r(20) r(26);

                .counter {
                    margin: 0 0 r(14);
                }

                .rating-row {
                    gap: r(40);
                    --star-size: #{r(15)};
                }

                .date {
                    font-size: r(12);
                }
            }
        }

        .slider-arrow {
            z-index: 1;
        }

        .slider-col {
            margin-top: r(38);
        }

        .btns {
            flex-direction: column;
            align-items: flex-start;
            margin: r(31) 0 0;
        }
    }
}