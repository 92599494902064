.auth-form {
    padding: r(60) r(14);

    .title {
        margin-bottom: r(25);
        text-align: center;
    }

    .store-password {
        display: flex;
        align-items: center;
        margin-top: r(34);

        input {
            margin-right: r(20);
        }
    }

    .form-input {
        &:not(:last-child) {
            margin-bottom: r(23);
        }
    }

    .submit {
        width: 100%;
        margin-top: r(25);
    }

    .form {
        max-width: r(420);
        margin: 0 auto;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: r(20);
        font-weight: 600;
    }

    .register-form {
        max-width: r(1032);
        margin: 0 auto;

        .fields {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: r(40) r(30);
            margin-bottom: r(25);
        }

        .form-input {
            grid-column: span 3;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                grid-column: span 2;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    .buttons-row {
        display: flex;
        justify-content: center;

        .submit {
            width: auto;
        }
    }

    .personal {
        margin-top: r(30);
    }

    @media (max-width: 1279px) {
        .register-form {
            .fields {
                gap: r(15);
                grid-template-columns: 1fr;
            }

            .form-input {
                grid-column: auto !important;
            }
        }
    }
}