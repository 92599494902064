.review-card {
  padding: r(36);
  background: #fff;
  border-radius: r(30);
  --star-size: #{r(22)};
  --star-active-color: #{$orange};
  .rating-row {
    display: flex;
  }
  .date {
    margin-left: r(20);
  }
  .name {
    margin-top: r(18);
  }
  .review-text {
    margin-top: r(24);
  }
  .bottom {
    margin-top: r(24);
    padding-top: r(24);
    border-top: 1px solid #969696;
    .toggle-row {
      display: flex;
      justify-content: space-between;
    }
  }
  .answer-text {
    padding-top: r(15);
  }
  .answer-toggle {
    border-bottom: 1px solid currentColor;
  }
  @media (max-width: 1279px) {
    padding: r(25) r(20);
    --star-size: #{r(18)};
    .date {
      font-size: r(12);
    }
    .name {
      margin-top: r(10);
      font-size: r(20);
    }
    .review-text {
      margin-top: r(15);
    }
    .bottom {
      margin-top: r(15);
      padding-top: r(15);
    }
    .answer-text {
      padding-top: r(10);
    }
  }
}
