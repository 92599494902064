.analyzes-page {
    padding: r(58) 0 r(150);

    .container {
        display: grid;
        grid-template-columns: 64.4% 1fr;
        justify-content: space-between;
    }

    .synonims {
        margin-top: r(30);
    }

    .analysis-card {
        width: 40%;
        margin-top: r(40);
    }

    .detail-text {
        margin-top: r(29);
    }

    .card-row {
        display: flex;
        align-items: flex-end;

        .file-link {
            flex: 0 0 auto;
            margin-left: r(30);
        }
    }

    @media (max-width: 1279px) {
        .container {
            grid-template-columns: 100%;
        }

        .analysis-card {
            width: 100%;
        }

    }

    @media (max-width: 575px) {
        .card-row {
            flex-direction: column;
            align-items: stretch;

            .file-link {
                margin: r(20) 0 0;
            }
        }
    }
}