.slider-arrow {
  width: r(57);
  height: r(57);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $light-green;
  .icon {
    width: r(17);
    height: r(13);
    fill: #fff;
  }
  &.prev {
    .icon {
      transform: rotate(180deg);
    }
  }
  &:hover {
    background: $dark-green;
  }
  @media (max-width: 1279px) {
    width: r(40);
    height: r(40);
    .icon {
      width: r(12);
      height: r(9);
    }
  }
}
