.recomend-analyzes {
    padding: 0 0 r(150);

    .title {
        margin-bottom: r(30);
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: r(27);
    }

    @media (max-width: 1279px) {
        .grid {
            grid-template-columns: 1fr;
        }
    }
}