.analyzes-search {
  padding: r(45) 0 r(56);
  background: $black;
  .title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: r(78);
  }
  .title {
    width: 34%;
  }
  .description {
    width: 46%;
    margin-top: r(13);
    color: #fff;
  }
  .form {
    display: flex;
    justify-content: space-between;
    .submit-btn {
      flex: 0 0 12.3%;
    }
  }
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 27.5%;
    position: relative;
    padding: r(15) r(30);
    color: #fff;
    border-bottom: 2px solid #fff;
    cursor: pointer;
    .arrow {
      width: r(17);
      height: r(8);
      fill: transparent;
      stroke: $light-green;
    }
  }
  @media (max-width: 1279px) {
    padding: r(30) 0 r(27);
    .title-row {
      display: block;
      margin-bottom: r(30);
    }
    .title {
      width: 100%;
    }
    .description {
      width: 100%;
      margin-top: r(10);
    }
    .form {
      flex-direction: column;
      gap: r(20);
      .submit-btn {
        flex: 0 0 auto;
        margin-top: r(10);
      }
    }
    .select {
      padding: r(10) r(21);
      .arrow {
        width: r(12);
        height: r(6);
      }
    }
  }
}
