.popular-analyzes {
  margin: r(270) 0 r(351);
  .main-wrap {
    position: relative;
    width: 86.45%;
    margin: 0 auto;
  }
  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: r(106) 0 r(116);
    background: $light-green;
    border-radius: r(60);
    .title {
      color: #fff;
      margin-bottom: r(19);
      text-align: center;
    }
  }
  @media (min-width: 1280px) {
    .analysis-card {
      width: r(450);
      position: absolute;
      z-index: 1;
      &:nth-child(1) {
        top: 0;
        left: r(75);
        transform: translate(0, -68%);
      }
      &:nth-child(2) {
        top: 0;
        right: r(75);
        transform: translate(0, -68%);
      }
      &:nth-child(3) {
        bottom: 0;
        left: r(239);
        transform: translate(0, 68%);
      }
      &:nth-child(4) {
        bottom: 0;
        right: r(208);
        transform: translate(0, 68%);
      }
    }
  }
  @media (max-width: 1279px) {
    margin: r(50) 0 r(60);
    .main-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: r(20);
    }
    .main-block {
      padding: r(37) 0 r(30);
      border-radius: r(30);
      .title {
        font-size: r(25);
      }
    }
    .analysis-card {
      order: 1;
    }
  }
}
