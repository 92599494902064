.faq-section {
  padding: r(90) 0;
  .faq {
    .question {
      display: flex;
      text-align: left;
      .icon {
        width: r(50);
        height: r(50);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin-right: r(28);
        border-radius: 50%;
        background: #fff;
        transition: background-color $tr-time;
        .plus {
          width: r(20);
          height: r(20);
          fill: $light-green;
          transition-property: transform, fill;
        }
      }
    }
    .faq-content {
      padding: r(14) 0 0 r(78);
    }
    &:not(:last-child) {
      margin-bottom: r(60);
    }
    &.open {
      .question {
        .icon {
          background: $light-green !important;
          .plus {
            transform: rotate(45deg);
            fill: #fff;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  .col {
    flex: 0 0 50%;
    margin: r(19) 0 0 0;
  }
  .title-col {
    flex: 0 0 35%;
  }
  .link {
    margin-top: r(30);
  }
  &.dark {
    background: $black;
    .title {
      color: $light-green;
    }
    .faq {
      .name {
        color: #fff;
      }
      .question {
        .icon {
          background: #fff;
        }
      }
    }
    .faq-content {
      color: #fff;
    }
  }
  &.light {
    background: #fff;
    .title {
      color: $dark-green;
    }
    .faq {
      .name {
        color: $dark-green;
      }
      .question {
        .icon {
          background: #f0f9f8;
        }
      }
    }
    .faq-content {
      color: $black;
    }
  }
  @media (max-width: 1279px) {
    padding: r(40) 0;
    .title-col {
      flex: 0 0 auto;
    }
    .container {
      flex-direction: column;
    }
    .col {
      flex: 0 0 auto;
    }
    .title-col {
      margin-bottom: r(30);
    }
    .link {
      margin-top: r(20);
    }
    .faq {
      .question {
        .icon {
          width: r(44);
          height: r(44);
          margin-right: r(9);
          .plus {
            width: r(17);
            height: r(17);
          }
        }
      }
      .faq-content {
        padding: r(12) 0 0 r(53);
      }
      &:not(:last-child) {
        margin-bottom: r(27);
      }
    }
  }
}
