.content-text {
  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }
  h3 {
    @include h3;
  }
  h4 {
    font-size: r(24);
  }
  h1,
  h2,
  h3,
  h4 {
    color: $dark-green;
    margin: r(80) 0 r(30);
  }
  p {
    margin-bottom: r(20);
  }
  p,
  li {
    @include text2;
  }
  a {
    color: $light-green;
    text-decoration: none;
    border-bottom: 2px solid currentColor;
    &:visited {
      color: $dark-green;
    }
  }
  img {
    display: block;
    margin: r(60) auto;
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }
  ul,
  ol {
    margin: r(20) 0 r(30);
    img {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      border-radius: 0;
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: r(20);
    }
    &::marker {
      color: $dark-green;
    }
  }
  ul {
    padding-left: r(32);
    list-style: none;
    li {
      position: relative;
      &::before {
        content: "";
        width: r(12);
        height: r(11);
        position: absolute;
        left: r(-32);
        top: r(6);
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjQ5MDAxIDEuNjc5NTZDNC43MTYxNyAtMC4xOTY2MjcgNy4yODYgLTAuNjM2MTMgOC4xNDM3MyAxLjA1NDY3TDExLjc5MzIgOC4yNDg2NkMxMi40Nzg5IDkuNjAwNDMgMTEuMzY1IDExLjE2MDYgOS44MzgzNiAxMC45ODY3TDEuNzEzNzMgMTAuMDYwN0MtMC4xOTU4MDQgOS44NDMxMSAtMC42NDkzOCA3LjMxODUzIDEuMDY4ODUgNi40NzEzNEw0LjA5MjA4IDQuOTgwNzFMNC40OTAwMSAxLjY3OTU2Wk0xMC4wNjEzIDkuMDk3MzJMNi40MTE4MSAxLjkwMzMzTDYuMDEzODggNS4yMDQ0OEM1LjkzNzM0IDUuODM5NDEgNS41NDEzNCA2LjM5NDA2IDQuOTU5ODcgNi42ODA3NkwxLjkzNjY0IDguMTcxMzlMMTAuMDYxMyA5LjA5NzMyWiIgZmlsbD0iIzI3NUY0MCIvPgo8L3N2Zz4K");
      }
    }
  }
  ol {
    padding-left: r(24);
    list-style: decimal outside;
  }
  .table {
    overflow: auto;
  }
  table {
    min-width: 100%;
    font-family: "Inter", sans-serif;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: r(30);
    overflow: hidden;
    td,
    th {
      padding: r(25) r(36);
      font-size: r(18);
      line-height: 1.4;
      border-bottom: 1px solid #d9f1db;
      background: #fff;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    tr {
      &:first-child {
        td {
          color: #fff;
          background: $light-green;
          border-right: 1px solid #fff;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
