.documents-section {
  margin: r(160) 0;
  .title {
    margin-bottom: r(70);
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: r(30);
  }
  .document {
    position: relative;
    border: 1px solid $light-green;
    border-radius: r(30);
    overflow: hidden;
    transition: filter $tr-time;
    .img {
      width: 100%;
    }
    .zoom {
      width: r(66);
      height: r(66);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      background: $light-green;
      transition: transform $tr-time;
      .icon {
        width: r(40);
        height: r(40);
        fill: #fff;
      }
    }
    &:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      .zoom {
        transform: scale(1.25);
      }
    }
  }
  @media (max-width: 1279px) {
    margin: r(50) 0 r(60);
    overflow: hidden;
    .title {
      margin-bottom: r(20);
    }
    .grid {
      grid-template-columns: auto;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      gap: r(16);
      margin: 0 r(-14);
      padding: 0 r(14);
      overflow: auto;
    }
    .document {
      width: r(228);
      border-radius: r(10);
      border-color: transparent;
    }
  }
}
