.certificate-present {
  .container {
    position: relative;
  }
  .date {
    margin-bottom: r(23);
    opacity: 0.6;
  }
  .description {
    margin-top: r(72);
  }
  .text-col {
    padding: r(113) 0 r(128);
    margin-left: auto;
    margin-right: r(36);
    width: 43.1%;
  }
  .img {
    position: absolute;
    top: r(80);
    left: r(14);
    height: 75%;
  }
  .tags {
    display: flex;
    gap: r(20);
    margin-top: r(35);
  }
  .tag {
    padding: r(13) r(26);
    color: #fff;
    background: $light-green;
    border-radius: r(13);
  }
  @media (max-width: 1279px) {
    overflow: hidden;
    .text-col {
      width: 100%;
      padding: r(20) 0 r(40);
      margin: 0;
    }
    .date {
      margin-bottom: r(20);
    }
    .tags {
      flex-direction: column;
      gap: r(15);
      margin: 0;
      align-items: flex-start;
    }
    .tag {
      padding: r(12) r(18);
      font-size: r(21);
      border-radius: r(11);
    }
    .description {
      width: 65%;
      margin-top: r(44);
    }
    .tags-col {
      position: relative;
      padding: r(33) 0 0;
      margin-top: r(11);
    }
    .img {
      width: r(369);
      height: r(235);
      left: r(54);
      top: 0;
      z-index: -1;
    }
  }
}
