.personal-account {
    .head {
        padding: 0 0 r(11);
        background: #fff;
    }

    .body {
        padding: r(40) 0 r(130);
    }

    .tabs {
        display: flex;
        gap: r(100);
        margin-top: r(58);

        .tab {
            color: $gray-green;

            &.current {
                color: $dark-green;
            }
        }
    }

    .personal-data {
        display: grid;
        grid-template-columns: 74.6% 1fr;
        gap: r(30);

        .actions-col {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .logout-btn {
            margin-top: r(10);
            color: $orange;
            font-size: r(16);
            line-height: 1.3;

            span {
                border-bottom: 1px solid currentColor;
            }
        }

        .form {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .fields {
                flex: 0 0 65.9%;
            }
        }

        .name-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: r(30);
            margin-bottom: r(28);
        }

        .password-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: r(20);
            align-items: flex-end;
            margin-top: r(60);
        }

        .info-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: r(19) r(30);
        }

        .save-btn {
            display: flex;
            align-items: center;
            color: $dark-green;
            font-size: r(16);
            line-height: 1.3;

            .icon {
                width: r(24);
                height: r(24);
                margin-right: r(10);
                fill: $dark-green;
            }

            span {
                border-bottom: 1px solid $dark-green;
            }
        }
    }

    .orders {
        display: grid;
        grid-template-columns: 74.6% 1fr;
        gap: r(30);
    }

    .order {
        padding: r(20) r(30);
        background: #fff;
        border-radius: r(30);

        .top {
            display: flex;
            padding: 0 0 r(20);
            border-bottom: 1px solid #dbdcdf;
        }

        .order-date {
            margin-left: r(12);
        }

        .order-status {
            margin-left: r(26);
        }

        .total {
            margin-left: auto;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            padding: r(20) 0 0;
        }
    }

    .analyzes-table {
        border: 1px solid $gray-green;

        table {
            width: 100%;
            border-collapse: collapse;

            td {
                padding: r(30);

                &:not(:last-child) {
                    border-right: 1px solid $gray-green;
                }
            }

            tr {
                &:last-child {
                    td {
                        padding: r(20) r(30);
                    }
                }

                &:not(:last-child) {
                    td {
                        border-bottom: 1px solid $gray-green;
                    }
                }
            }
        }
    }

    .order-wrap {
        .table-wrap {
            margin-top: r(8);
            border-radius: r(30);
            overflow: auto;
        }

        &:not(:last-child) {
            margin-bottom: r(30);
        }
    }

    .bookmarks {
        display: grid;
        grid-template-columns: 74.6% 1fr;
        gap: r(30);
        align-items: flex-start;

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: r(24) r(30);
        }
    }

    @media (max-width: 1279px) {
        .personal-data {
            grid-template-columns: 1fr;

            .form {
                flex-direction: column;
            }

            .actions-col {
                margin-top: r(20);
            }

            .save-btn {
                font-size: r(12);

                .icon {
                    width: r(17);
                    height: r(17);
                    margin-right: r(5);
                }
            }

            .info-row {
                gap: r(20) r(12);

                .form-input-wrap {

                    &:nth-child(3),
                    &:nth-child(4) {
                        grid-column: span 2;
                    }
                }
            }

            .name-row {
                grid-template-columns: 1fr;
                margin-bottom: r(20);
                gap: r(20);
            }

            .password-row {
                grid-template-columns: 1fr;
                gap: r(10);
                margin-top: r(20);
            }
        }

        .body {
            padding: r(20) 0 r(50);
        }

        .tabs {
            gap: 0;
            justify-content: space-between;
        }

        .orders {
            grid-template-columns: 100%;
        }

        .order {
            .top {
                flex-wrap: wrap;
            }

            .bottom {
                flex-direction: column;
                gap: r(15);
            }

            .order-status {
                margin: r(5) 0 0;
                flex: 0 0 100%;
                font-size: r(12);
            }

            .total {
                flex: 0 0 100%;
                margin: r(15) 0 0;
            }

            .cancel-order {
                width: 100%;
            }
        }

        .analyzes-table {
            table {
                min-width: r(870);
            }
        }

        .bookmarks {
            grid-template-columns: 1fr;

            .grid {
                grid-template-columns: 1fr;
            }
        }
    }
}