.vacancies-section {
  margin: r(34) 0 r(150);
  .title {
    margin-bottom: r(35);
  }
  .items-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: r(30);
  }
  .pagination-wrap {
    margin-top: r(50);
  }
  .pagination {
    justify-content: flex-end;
  }
  @media (max-width: 1279px) {
    margin: r(34) 0 r(60);
    .items-grid {
      grid-template-columns: 1fr;
    }
  }
}
