.about-company {
  margin: r(160) 0;
  .container {
    display: grid;
    grid-template-columns: 46.5% 42.4%;
    gap: r(130);
  }
  .img {
    border-radius: r(60);
  }
  .subtitle {
    margin-bottom: r(23);
  }
  .title-row {
    margin-bottom: r(50);
  }
  .title {
    position: relative;
    display: inline-block;
    .label {
      position: absolute;
      left: calc(100% + r(30));
      top: r(-25);
      display: inline-block;
      padding: r(12) r(20) r(14);
      color: #fff;
      background: $orange;
      border-radius: r(10);
    }
  }
  .link {
    margin-top: r(40);
  }
  @media (max-width: 1279px) {
    margin: r(60) 0 r(50);
    .container {
      grid-template-columns: 1fr;
      gap: 0;
    }
    .img {
      width: 100%;
      margin: 0 0 r(29);
      border-radius: r(30);
    }
    .subtitle {
      margin: 0 0 r(20);
    }
    .title-row {
      margin: 0 0 r(32);
    }
    .title {
      .label {
        position: static;
        padding: r(6) r(16) r(8);
        font-weight: 700;
      }
    }
    .link {
      margin: r(20) 0 0;
    }
  }
}
