.vacancy-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: r(45) r(30) r(37);
  position: relative;
  min-height: r(353);
  z-index: 1;
  background: #fff;
  overflow: hidden;
  border-radius: r(30);
  cursor: pointer;
  .name {
    margin-bottom: auto;
    padding-bottom: r(17);
  }
  .payment {
    font-size: r(30);
    line-height: 1.2;
    font-weight: 600;
  }
  .btn {
    margin-top: r(47);
  }
  .fancy-bg {
    width: r(198);
    height: r(270);
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }
  @media (max-width: 1279px) {
    padding: r(20);
    min-height: r(241);
    border-radius: r(20);
    .payment {
      font-size: r(25);
    }
    .name {
      font-size: r(20);
    }
    .btn {
      margin-top: r(20);
    }
    .fancy-bg {
      width: r(119);
      height: r(162);
    }
  }
}
