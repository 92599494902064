.footer-form {
  padding: r(48) 0 r(43);
  background: #fff;
  --form-text-color: #{$black};
  --form-placeholder-color: #{$gray-green};
  --form-border-color: #{$light-green};
  --form-personal-color: #{$light-green};
  --form-personal-link-color: #{$light-green};
  --link-hover-color: #{$dark-green};
  --form-error-color: #f00;
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(30);
    align-items: flex-start;
  }
  .form-text {
    align-self: flex-end;
  }
  .top {
    margin-bottom: r(67);
  }
  .submit-btn {
    width: 100%;
  }
  .personal {
    margin-top: r(20);
    text-align: center;
  }
  @media (max-width: 1279px) {
    padding: r(30) 0;
    .grid {
      grid-template-columns: 1fr;
      gap: r(10);
    }
    .top {
      margin-bottom: r(38);
    }
    .personal {
      margin-top: r(16);
    }
  }
}
