.modal-results {
  .modal {
    &__container {
      max-width: 79.7%;
      padding: r(60) r(70);
      background: #f0f9f8;
      border-radius: r(30);
    }
  }
  .title {
    margin-bottom: r(35);
    text-align: center;
  }
  .results-table {
    max-height: r(540);
    border-radius: r(30);
    overflow: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      padding: r(33) r(21);
      color: #fff;
      font-weight: normal;
      text-align: left;
      background: $light-green;
      &:not(:last-child) {
        border-right: 1px solid #fff;
      }
    }
    td {
      padding: r(30);
      background: #fff;
    }
    tr {
      &:not(:last-child) {
        td {
          border-bottom: 1px solid $pale-green;
        }
      }
    }
    .file {
      display: flex;
      align-items: flex-start;
      color: $light-green;
      .icon {
        width: r(28);
        height: r(37);
        margin-right: r(15);
        fill: currentColor;
      }
      &.inactive {
        color: $gray-green;
        pointer-events: none;
      }
    }
  }
  @media (max-width: 1279px) {
    table {
      min-width: r(950);
      th {
        padding: r(29) r(20);
      }
    }
    .modal {
      &__container {
        padding: r(25) r(20) r(50);
      }
    }
    .title {
      width: r(175);
      margin: 0 auto r(20);
    }
  }
}
