.content-services {
    .service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: r(15) r(30) r(16);
        flex: 1 1 auto;
        border-bottom: 1px solid $dark-green;
    }

    .service-row {
        display: flex;
        gap: r(15);
        align-items: flex-end;

        &:not(:last-child) {
            margin-bottom: r(40);
        }

        .btn {
            flex: 0 0 auto;
        }

        .price {
            flex: 0 0 auto;
        }

        .name {
            flex: 0 0 80%;
        }
    }

    .services-title {
        margin-bottom: r(40);
    }

    @media (max-width: 1279px) {
        .service {
            padding: r(15) r(10);
            align-self: stretch;

            .name,
            .price {
                font-size: r(14);
            }
        }

        .service-row {
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: r(10);
            }
        }

        .services-title {
            margin-bottom: r(20);
        }
    }
}