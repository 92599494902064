.category-select {
  width: 51.4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: r(15) 0;
  margin-bottom: r(42);
  grid-column: 1/2;
  border-bottom: 1px solid $dark-green;
  cursor: pointer;
  .active {
    color: $gray-green;
  }
  .arrow {
    width: r(17);
    height: r(8);
    flex: 0 0 auto;
    transform: rotate(-90deg);
    fill: transparent;
    stroke: $dark-green;
    stroke-width: 3px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    margin-bottom: r(30);
  }
}
