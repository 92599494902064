.modal-action {
  .modal {
    &__container {
      max-width: 85.75%;
      margin: 0 auto;
      padding: r(100) r(90);
      position: relative;
      z-index: 1;
      background: #f0f9f8;
      border-radius: r(60);
      overflow: hidden;
    }
    &__close {
      top: r(50);
      right: r(50);
    }
  }
  .fancy-bg {
    width: r(1233);
    height: r(693);
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }
  .subtitle {
    grid-column: span 2;
    margin-bottom: r(40);
  }
  .grid {
    display: grid;
    grid-template-columns: 40% 50%;
    justify-content: space-between;
  }
  .img-col {
    align-self: flex-end;
  }
  .img {
    width: 100%;
    border-radius: r(60);
  }
  @media (max-width: 1279px) {
    .modal {
      &__container {
        padding: r(40) r(20) r(20);
        border-radius: r(40);
      }
      &__close {
        top: r(25);
        right: r(25);
      }
    }
    .img {
      width: 82%;
      margin: 0 auto;
      border-radius: (r(40));
    }
    .img-col {
      grid-row: 1/2;
      margin-bottom: r(35);
    }
    .subtitle {
      grid-column: auto;
      margin-bottom: r(15);
      font-size: r(20);
    }
    .grid {
      grid-template-columns: 1fr;
    }
  }
}
