.content-block {
  &:not(:last-child) {
    margin-bottom: r(120);
  }
  @media (max-width: 1279px) {
    &:not(:last-child) {
      margin-bottom: r(50);
    }
  }
}
