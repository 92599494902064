.analyzes-section {
  padding: r(47) 0 r(150);
  .title {
    margin-bottom: r(40);
  }
  .container {
    display: grid;
    grid-template-columns: 75.1% 1fr;
    gap: 0 r(49);
  }
  .items-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: r(30);
    grid-column: 1/2;
  }
  .pagination-wrap {
    grid-column: span 2;
  }
  .pagination {
    margin-top: r(30);
  }
  .search-form {
    display: flex;
    height: r(58);
    position: relative;
    grid-row: 3/4;
    grid-column: 2/3;
    .input {
      width: 100%;
      height: 100%;
      padding: 0 r(30);
      background: #fff;
      border: 0;
      border-radius: r(10);
      &::placeholder {
        color: $light-green;
      }
    }
    .search-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  @media (max-width: 1279px) {
    padding: r(34) 0 r(100);
    .items-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    .container {
      grid-template-columns: 1fr;
    }
    .search-form {
      height: r(54);
      margin-bottom: r(40);
      .search-btn {
        height: 100%;
      }
    }
    .category-select,
    .search-form,
    .pagination-wrap {
      grid-column: auto;
      grid-row: auto;
    }
  }
  @media (max-width: 991px) {
    .items-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 767px) {
    .items-grid {
      grid-template-columns: 1fr;
    }
  }
}
