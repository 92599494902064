.default-form-colors {
    // --form-bg-color: #fff;
    // --form-border-color: #fff;
    // --form-text-color: #{$black};
    // --form-input-placeholder-color: #c8c8c8;
    // --form-personal-link-color: #{$black};
    //   --form-status-icon-color: #{$white};
    // --form-status-scale: 0.8;
    --form-personal-color: #{$light-green};
    --form-placeholder-color: #{$gray-green};
    --form-error-color: #f00;
    --form-text-color: #{$dark-green};
    --form-bg-color: #fff;
    --form-input-placeholder-color: #{$gray-green};
    --form-border-color: #{$light-green};
}