.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  &.is-open {
    display: block;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15vh 0 0;
    overflow: auto;
    background: rgba(#000, 0.5);
  }
  &__close {
    position: absolute;
    top: r(29);
    right: r(29);
    z-index: 2;
    cursor: pointer;
    background: transparent;
    .icon {
      width: r(27);
      height: r(27);
      fill: $light-green;
    }
  }
  &__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  &[aria-hidden="false"] {
    .modal {
      &__overlay {
        animation: mmfadeIn $tr-time cubic-bezier(0, 0, 0.2, 1);
      }
      &__container {
        animation: mmslideIn $tr-time cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
  &[aria-hidden="true"] {
    .modal {
      &__overlay {
        animation: mmfadeOut $tr-time cubic-bezier(0, 0, 0.2, 1);
      }
      &__container {
        animation: mmslideOut $tr-time cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
  @media (max-width: 1279px) {
    &__container {
      max-width: 91.2% !important;
    }
    &__close {
      right: r(25);
      top: r(25);
      .icon {
        width: r(15);
        height: r(15);
      }
    }
  }
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
