.partner-section {
  margin: r(100) 0;
  .container {
    display: flex;
    justify-content: space-between;
  }
  .text-col {
    flex: 0 0 45%;
  }
  .content-text {
    margin-top: r(35);
  }
  .img {
    flex: 0 0 auto;
    width: 49%;
    border-radius: r(60);
  }
  @media (max-width: 1279px) {
    margin: r(50) 0;
    .container {
      flex-direction: column;
    }
    .img {
      width: 100%;
      margin-top: r(30);
      border-radius: r(40);
    }
    .content-text {
      margin-top: r(14);
    }
  }
}
