.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  .prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: r(57);
    height: r(57);
    border: 1px solid $gray-green;
    border-radius: 50%;
    .arrow {
      width: r(17);
      height: r(13);
      fill: $gray-green;
      transform: rotate(180deg);
    }
  }
  .next {
    &::before {
      content: "Следующая страница";
    }
  }
  .pages {
    display: flex;
    gap: r(30);
    margin: 0 r(50);
    color: $gray-green;
    .current {
      color: $dark-green;
    }
  }
  @media (max-width: 1279px) {
    .pages {
      margin: 0 r(26);
      gap: r(10);
    }
    .prev {
      width: r(49);
      height: r(49);
      .arrow {
        width: r(15);
        height: r(11);
      }
    }
    .next {
      padding: 0 r(20);
      height: r(49);
      &::before {
        content: "";
      }
      .arrow {
        width: r(17);
        height: r(13);
        margin: 0;
      }
    }
  }
}
