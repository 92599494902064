.contacts-section {
  padding: r(58) 0 r(150);
  .title {
    margin-bottom: r(35);
  }
  .map {
    height: r(698);
    background: #aaa;
    border-radius: r(60);
    overflow: hidden;
  }
  .row {
    display: flex;
    gap: r(60);
    margin-bottom: r(50);
  }
  .contact {
    max-width: r(270);
    .name {
      margin-bottom: r(11);
    }
  }
  @media (max-width: 1279px) {
    padding: r(34) 0 r(60);
    .row {
      flex-direction: column;
      gap: r(20);
      margin-bottom: r(41);
    }
    .contact {
      .name {
        margin-bottom: r(10);
      }
    }
    .map {
      height: r(408);
      border-radius: r(30);
    }
  }
}
