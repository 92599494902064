.actions-slider {
    position: relative;
    background: #fff;

    .title {
        margin-bottom: r(23);
        opacity: 0.6;
    }

    .btn {
        margin-top: r(30);
    }

    .text-col {
        width: 40%;
    }

    .swiper-slide {
        padding: r(57) r(195) r(78);
        height: auto;
    }

    .slider-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &.prev {
            left: r(75);
        }

        &.next {
            right: r(75);
        }
    }

    .labels {
        margin: r(35) 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: r(16);

        .label {
            padding: r(10) r(20);
            border-radius: r(10);
            border: 1px solid rgba(51, 51, 51, 0.5);

            &:first-child {
                color: #fff;
                background: $black;
                border-color: $black;
            }
        }
    }

    @media (max-width: 1279px) {
        .swiper-slide {
            min-height: r(566);
            padding: r(56) r(14) r(30);
        }

        .text-col {
            width: 100%;
        }

        .labels {
            margin: r(27) 0 0;
        }

        .title {
            width: 100%;
            padding: 0 r(14);
            top: r(20);
        }

        .slider-arrow {
            top: unset;
            bottom: r(30);

            &.prev {
                left: r(14);
            }

            &.next {
                right: r(14);
            }
        }

        .slide-title {
            width: 75%;
        }
    }
}