.mobile-menu {
    position: absolute;
    top: r(105);
    left: 0;
    width: 100%;
    padding: r(30) r(20);
    z-index: 9;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);

    .menu {
        .item {
            display: block;
            font-size: r(22);

            &:not(:last-child) {
                margin-bottom: r(14);
            }
        }
    }

    @media (min-width: 1280px) {
        display: none;
    }

    &:not(.open) {
        display: none;
    }

    .contacts {
        margin-top: r(16);

        .tel {
            display: block;
        }
    }

    .actions {
        margin-bottom: r(22);

        .action {
            display: flex;
            align-items: center;
            gap: r(10);

            .icon {
                width: r(24);
                height: r(24);
                fill: currentColor;
            }

            &:not(:last-child) {
                margin-bottom: r(10);
            }
        }
    }
}