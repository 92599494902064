.analysis-card {
    display: flex;
    flex-direction: column;
    padding: r(30) r(30) r(25);
    min-height: r(257);
    position: relative;
    border-radius: r(34);
    background: #fff;
    box-shadow: 0px 4px 7.8px 0px rgba(194, 195, 197, 0.2);

    .name {
        margin-bottom: r(24);
    }

    .med-info {
        margin: auto 0 r(14);
        display: flex;
        justify-content: space-between;

        .execution {
            display: flex;
            align-items: center;

            .icon {
                width: r(19);
                height: r(20);
                margin-right: r(10);
                flex: 0 0 auto;
                fill: $dark-green;
            }
        }
    }

    .price-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .price-block {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .old-price {
        flex: 0 0 100%;
        margin-bottom: r(2);
        opacity: 0.45;
        font-weight: 700;
        text-decoration-line: line-through;
    }

    .price {
        font-weight: 600;
    }

    .discount-text {
        margin-bottom: r(10);
    }

    .discount {
        margin: 0 0 r(6) r(10);
        padding: r(3) r(5);
        color: #fff;
        background: $orange;
        border-radius: r(10);
    }

    .add-to-cart {
        flex: 0 0 auto;
    }

    .btn {
        box-shadow: 0px 3px 26.5px -4px rgba(70, 189, 76, 0.5);
    }

    .add-to-bookmark {
        position: absolute;
        top: r(-7);
        right: r(24);

        .icon {
            width: r(24);
            height: r(29);
            fill: transparent;
        }

        &.active {
            .icon {
                fill: $light-green;
            }
        }
    }

    &.compact {
        min-height: auto;

        .price-row {
            margin: 0;
        }
    }

    @media (max-width: 1279px) {
        min-height: r(160);

        .name {
            margin-bottom: r(20);
        }

        .old-price {
            font-size: r(12);
        }

        .price {
            font-size: r(30);
        }
    }
}