.actions-section {
  margin: r(58) 0 r(150);
  .items-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: r(40) r(30);
    margin-top: r(40);
  }
  .pagination {
    justify-content: flex-end;
    &-wrap {
      margin-top: r(30);
    }
  }
  @media (max-width: 1279px) {
    margin: r(34) 0 r(100);
    .items-grid {
      grid-template-columns: 1fr;
      gap: r(20);
      margin-top: r(15);
    }
    .pagination {
      justify-content: space-between;
    }
  }
}
