@mixin h1 {
  font-size: r(64);
  line-height: 1.1;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(30);
    font-weight: normal;
    line-height: 1.15;
  }
}

@mixin h2 {
  font-size: r(40);
  line-height: 1.1;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(30);
  }
}

@mixin h3 {
  font-size: r(32);
  line-height: 1.2;
  font-weight: 300;
  @media (max-width: 1279px) {
    font-size: r(18);
  }
}

@mixin text1 {
  font-size: r(25);
  line-height: 1.2;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    @include mob-text1;
  }
}

@mixin mob-text1 {
  font-size: r(18);
}

@mixin text2 {
  font-size: r(20);
  line-height: 1.3;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    @include mob-text2;
  }
}

@mixin mob-text2 {
  font-size: r(14);
}

@mixin caption {
  font-size: r(18);
  line-height: 1.3;
  font-family: "Inter", sans-serif;
  @media (max-width: 1279px) {
    font-size: r(10);
  }
}
