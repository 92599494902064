.reviews-page {
  margin: r(58) 0 r(150);
  .title {
    margin-bottom: r(25);
  }
  .pagination-wrap {
    margin-top: r(49);
  }
  .pagination {
    justify-content: flex-end;
  }
  .reviews-links {
    display: flex;
    gap: r(30);
    margin-bottom: r(41);
  }
  .grid {
    display: grid;
    grid-template-columns: 28.3% 1fr;
    gap: r(100);
  }
  .form-title {
    margin-bottom: r(40);
  }
  .form-col {
    --form-text-color: #333;
    --form-placeholder-color: #9db7a9;
    --form-border-color: #41bb4c;
    --form-personal-color: #{$dark-green};
    --form-personal-link-color: #{$dark-green};
    --link-hover-color: #275f40;
    --form-error-color: #f00;
    --star-size: #{r(36)};
    --star-active-color: #{$orange};
    .submit-btn {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .row-input {
      width: 100%;
      margin-bottom: r(35);
      background: transparent;
    }
    .rating-row {
      display: flex;
      align-items: center;
      gap: r(30);
      margin: 0 0 r(40) r(30);
    }
    .review-text {
      display: block;
      width: 100%;
      padding: r(29) r(30);
      height: r(265);
      background: transparent;
      border: 1px solid $light-green;
      border-radius: r(30);
      resize: none;
      &.error {
        border-color: var(--form-error-color);
      }
    }
    .personal {
      margin: r(21) 0 0 r(30);
      &__link {
        text-decoration: underline;
      }
    }
    .textarea {
      position: relative;
    }
  }
  .review-card {
    &:not(:last-child) {
      margin-bottom: r(30);
    }
  }
  @media (max-width: 1279px) {
    margin: r(34) 0 r(100);
    .title {
      margin-bottom: r(13);
    }
    .grid {
      grid-template-columns: 1fr;
      gap: r(30);
    }
    .reviews-links {
      flex-direction: column;
      gap: r(10);
      margin-bottom: r(30);
    }
    .form-title {
      margin-bottom: r(15);
    }
    .form-col {
      --star-size: #{r(30)};
      .rating-row {
        margin: r(15) 0 r(40);
      }
      .row-input {
        margin-bottom: r(20);
      }
      .review-text {
        height: r(171);
      }
      .personal {
        margin: r(20) 0 0;
      }
    }
    .review-card {
      &:not(:last-child) {
        margin-bottom: r(20);
      }
    }
  }
}
