.not-found-section {
  padding: r(90) 0;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    width: 53%;
    margin: r(20) auto r(30);
    text-align: center;
  }
  .img {
    width: r(636);
    height: r(443);
  }
  @media (max-width: 1279px) {
    padding: r(50) 0;
    .img {
      width: 82%;
      height: auto;
      margin: 0 auto;
    }
    .title {
      width: 94%;
      margin: r(20) 0;
    }
    .btn {
      width: 100%;
    }
  }
}
