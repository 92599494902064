.action-card {
  position: relative;
  padding: r(30);
  min-height: r(555);
  z-index: 1;
  background: #fff;
  border-radius: r(30);
  cursor: pointer;
  .slider-arrow {
    position: absolute;
    left: r(30);
    bottom: r(30);
    pointer-events: none;
  }
  &:hover {
    .slider-arrow {
      background: $dark-green;
    }
  }
  @media (max-width: 1279px) {
    padding: r(20);
    min-height: r(374);
    border-radius: r(20);
    .name {
      font-size: r(20);
    }
    .slider-arrow {
      left: r(20);
      bottom: r(20);
    }
  }
}
