.main-screen {
  position: relative;
  background: #fff;
  overflow: hidden;
  &::after {
    content: "";
    width: r(1432);
    height: r(1432);
    position: absolute;
    top: -55%;
    left: -19%;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
  }
  .text-col {
    padding: r(156) 0 r(200);
    width: 43%;
    position: relative;
    z-index: 2;
  }
  .img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 54.67%;
    object-fit: cover;
  }
  .search-form {
    width: 94.7%;
    margin-top: r(61);
    position: relative;
    .input {
      width: 100%;
      padding: 0 r(30);
      height: r(58);
      background: #e9f8ee;
      border: 0;
      border-radius: r(10);
      &::placeholder {
        color: $light-green;
      }
    }
    .search-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    .container {
      margin-top: r(-28);
      order: 1;
    }
    &::after {
      width: r(779);
      height: r(779);
      top: r(282);
      left: 0;
      transform: translate(-28%, 0);
    }
    .text-col {
      width: 100%;
      padding: 0 0 r(20);
      background: #fff;
    }
    .img {
      width: 100%;
      height: r(358);
      position: static;
    }
    .search-form {
      width: 100%;
      margin-top: r(20);
      .input {
        height: r(41);
        padding: 0 r(20);
      }
    }
  }
}
