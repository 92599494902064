.modal-order {
  .modal {
    &__container {
      padding: r(60) r(106) r(73);
      max-width: 64.9%;
      background: #f0f9f8;
      border-radius: r(30);
    }
  }
  .title {
    margin-bottom: r(25);
    text-align: center;
  }
  .form {
    display: grid;
    gap: r(23) r(30);
    grid-template-columns: repeat(2, 1fr);
    .submit-btn {
      grid-column: span 2;
    }
    .payment {
      &:not(:last-child) {
        margin-bottom: r(16);
      }
    }
  }
  @media (max-width: 1279px) {
    .modal {
      &__container {
        padding: r(25) r(20);
      }
    }
    .payments {
      margin: r(5) 0;
    }
    .form {
      grid-template-columns: 1fr;
      gap: r(10);
      .submit {
        grid-column: auto;
      }
    }
    .payment {
      &:not(:last-child) {
        margin-bottom: r(15);
      }
    }
    .title {
      margin-bottom: r(20);
    }
    .submit-btn {
      margin-top: r(5);
    }
  }
}
