header {
    position: relative;
    z-index: 10;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);

    .logo {
        .icon {
            width: r(121);
            height: r(44);
            fill: $dark-green;
        }
    }

    .container {
        padding-top: r(19);
        padding-bottom: r(14);
        display: flex;
        align-items: center;
    }

    .address {
        width: r(214);
        padding: r(4) 0 r(4) r(14);
        margin-left: r(15);
        border-left: 1px solid $light-green;
    }

    .right {
        display: flex;
        align-items: center;
        gap: r(30);
        margin-left: auto;
    }

    .menu {
        display: flex;
        align-items: center;
        gap: r(20);
        margin-left: auto;

        .submenu {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: r(24) r(50);
            padding: r(30) r(50) r(40);
            position: absolute;
            left: r(-50);
            top: 140%;
            z-index: 3;
            white-space: nowrap;
            background: #fff;
            box-shadow: 0px 4px 12px 0px rgba(154, 156, 179, 0.2);
        }

        .link {
            &:hover {
                text-decoration: underline;
            }
        }

        .parent {
            position: relative;

            >.link {
                display: flex;
                align-items: center;
            }

            .menu-open {
                width: r(10);
                height: r(11);
                margin-right: r(6);
                stroke: currentColor;
            }

            .menu-close {
                width: r(10);
                height: r(8);
                margin-right: r(6);
                stroke: currentColor;
            }

            &:not(.open) {
                .menu-close {
                    display: none;
                }

                .submenu {
                    display: none;
                }
            }

            &.open {
                .menu-open {
                    display: none;
                }
            }
        }
    }

    .action {
        display: flex;
        align-items: center;
        gap: r(10);

        .icon {
            width: r(24);
            height: r(24);
            fill: currentColor;
        }
    }

    .menu-burger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: r(39);
        height: r(39);
        margin-left: auto;
        flex: 0 0 auto;
        background: $light-green;
        border-radius: r(10);

        .icon {
            fill: #fff7f1;
        }

        .burger-icon {
            width: r(21);
            height: r(14);
        }

        .close-icon {
            width: r(19);
            height: r(19);
        }

        &.open {
            .burger-icon {
                display: none;
            }
        }

        &:not(.open) {
            .close-icon {
                display: none;
            }
        }
    }

    @media (min-width: 1280px) {
        .menu-burger {
            display: none;
        }
    }

    @media (max-width: 1279px) {

        .menu,
        .right {
            display: none;
        }

        .address {
            margin-left: r(13);
            padding: 0;
            border-left: 0;
        }

        .logo {
            .icon {
                width: r(121);
                height: r(44);
            }
        }
    }
}