.reviews-link {
  display: flex;
  align-items: center;
  .icon {
    width: r(40);
    height: r(40);
    flex: 0 0 auto;
    margin-right: r(11);
  }
  .caption {
    position: relative;
    top: r(-8);
  }
  @media (max-width: 1279px) {
    .icon {
      width: r(35);
      height: r(35);
      margin-right: r(10);
    }
  }
}
