.section-advantages {
  margin: r(160) 0;
  .container {
    display: grid;
    grid-template-columns: 45.4% 49%;
    justify-content: space-between;
  }
  .title {
    margin-bottom: r(50);
  }
  .img-col {
    align-self: center;
    grid-row: span 2;
  }
  .img {
    width: 100%;
    border-radius: r(60);
  }
  .swiper {
    margin-bottom: r(84);
  }
  .arrows {
    display: flex;
    gap: r(30);
    margin-top: r(30);
  }
  @media (max-width: 1279px) {
    margin: r(50) 0;
    .container {
      grid-template-columns: 100%;
    }
    .title {
      margin-bottom: r(40);
    }
    .img-col {
      grid-column: auto;
      margin-bottom: r(31);
    }
    .img {
      border-radius: r(40);
    }
    .swiper {
      margin-bottom: r(30);
    }
    .arrows {
      margin: r(20) 0 0;
      gap: r(20);
    }
  }
}
