.doctor-page {
  margin: r(58) 0 r(150);
  .doctor-img {
    width: 100%;
    height: r(550);
    border-radius: r(60);
    object-fit: cover;
  }
  .doctor-name {
    margin-bottom: r(28);
  }
  .doctor-prop {
    display: flex;
    align-items: flex-start;
    .icon {
      width: r(64);
      height: r(61);
      margin-right: r(28);
      flex: 0 0 auto;
      fill: $light-green;
    }
  }
  .doctor-props {
    display: flex;
    justify-content: space-between;
    margin-top: r(30);
    gap: r(70);
  }
  .container {
    display: grid;
    gap: r(60);
    grid-template-columns: 72.9% 1fr;
  }
  .left-col {
    .top {
      display: grid;
      grid-template-columns: 44.4% 1fr;
      gap: r(60);
    }
  }
  .sign-up {
    margin-top: r(73);
    &-btn {
      margin-top: r(28);
    }
  }
  .doctor-content {
    margin-top: r(80);
  }
  .sidebar {
    position: sticky;
    top: r(60);
    left: 0;
    padding: r(18) r(30);
    background: #fff;
    border-radius: r(30);
    .item {
      display: block;
      padding: r(25) 0;
      &:not(:last-child) {
        border-bottom: 1px solid $light-green;
      }
    }
  }
  @media (max-width: 1279px) {
    margin: r(34) 0 r(50);
    .doctor-content {
      margin-top: r(50);
    }
    .container {
      gap: 0;
      grid-template-columns: 1fr;
    }
    .left-col {
      .top {
        grid-template-columns: 1fr;
        gap: r(20);
      }
    }
    .doctor-prop {
      .icon {
        width: r(37);
        height: r(35);
        margin-right: r(10);
      }
      .text2 {
        font-size: r(12);
      }
    }
    .doctor-name {
      margin-bottom: r(15);
    }
    .doctor-props {
      margin-top: r(25);
      gap: r(32);
    }
    .doctor-img {
      width: 72.4%;
      height: auto;
      margin: 0 auto;
      border-radius: r(40);
    }
    .sidebar-col {
      display: none;
    }
    .sign-up {
      margin-top: r(35);
      &-btn {
        margin-top: r(20);
      }
    }
  }
}
