.head-marquee {
  display: flex;
  padding: r(8) 0;
  background: $dark-green;
  overflow: hidden;
  .flow {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    animation: flow 140s linear 0s infinite normal forwards;
  }
  .message {
    flex: 0 0 auto;
    color: #fff;
    text-transform: uppercase;
    font-size: r(14);
  }
  .ellipse {
    width: r(6);
    height: r(6);
    margin: 0 r(20);
    flex: 0 0 auto;
    background: #ecf2ee;
    border-radius: 50%;
  }
  @media (max-width: 1279px) {
    .message {
      font-size: r(10);
    }
    .ellipse {
      width: r(4.5);
      height: r(4.5);
      margin: 0 r(15);
    }
  }
}

@keyframes flow {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
