.cart-section {
    padding: r(58) 0 0;

    .cart-title {
        margin-bottom: r(35);
    }

    .cart {
        display: grid;
        grid-template-columns: 74.6% 1fr;
        gap: r(30);
    }

    .table {
        .empty-cart {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #fff;
            border-radius: r(30);

            .link {
                margin-top: r(40);
            }
        }

        table {
            border-radius: r(30);
            overflow: hidden;
            width: 100%;
            font-size: r(20);
            line-height: 1.3;
            border-collapse: collapse;
            background: #fff;

            th {
                padding: r(20) r(24);
                font-weight: normal;
                border: 1px solid #fff;
                border-bottom: 0;
                border-top: 0;
                border-left: 0;
                text-align: left;
                color: #fff;
                background: $light-green;
            }

            th,
            td {
                &:last-child {
                    border-right: 0;
                }
            }

            th,
            td {
                &:first-child {
                    border-left: 0;
                }
            }

            .num,
            .price {
                text-align: center;
            }

            td {
                border: 1px solid $pale-green;
                padding: r(30) r(24);
            }

            tfoot {
                color: $dark-green;

                td {
                    padding-top: r(20);
                    padding-bottom: r(20);
                    border-bottom: 0;
                }
            }
        }
    }

    .sidebar {
        .head {
            padding: r(33) r(30);
            color: #fff;
            background: $black;
            border-radius: r(30) r(30) 0 0;
        }

        .body {
            padding: r(30) r(35);
            background: #fff;
            border-radius: 0 0 r(30) r(30);
        }

        .total {
            padding-top: r(20);
            border-top: 1px solid $light-green;
        }

        .price-row {
            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: r(20);
            }
        }

        .order-btn {
            width: 100%;
            margin-top: r(30);
        }
    }

    .description {
        margin-top: r(40);
    }

    @media (max-width: 1279px) {
        .cart {
            grid-template-columns: 100%;
            gap: r(50);
        }

        .table {
            overflow: auto;
            margin: 0 r(-14);
            padding: 0 r(14);

            .empty-cart {
                padding: r(54) 0;

                .link {
                    margin-top: r(20);
                }
            }

            table {
                font-size: r(14);

                th {
                    padding: r(22.5) r(20);
                    white-space: nowrap;
                }

                td {
                    padding: r(30) r(30) r(25);
                }

                .name {
                    min-width: r(280);
                }
            }
        }

        .sidebar {
            .head {
                padding: r(16) r(24);
                border-radius: r(20) r(20) 0 0;
            }

            .body {
                padding: r(20) r(24);
                border-radius: 0 0 r(20) r(20);
            }

            .price-row {
                &:not(:last-child) {
                    margin-bottom: r(20);
                }
            }

            .total {
                padding-top: r(15);
            }

            .order-btn {
                margin-top: r(20);
            }
        }

        .cart-title {
            margin-bottom: r(20);
        }
    }
}