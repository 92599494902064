.rating-stars {
    display: flex;

    .star {
        flex: 0 0 auto;
        width: var(--star-size);
        height: var(--star-size);

        .icon {
            width: 100%;
            height: 100%;
            fill: #dbdcdf;
        }

        &.active {
            .icon {
                fill: var(--star-active-color);
            }
        }
    }
}